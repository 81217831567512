<template>
  <div>
    <div class="row q-pa-xs border-bottom q-gutter-md q-items-center">
      <img
          :style="{ height: getOptions.image.height + 'px', width: getOptions.image.width + 'px', 'object-fit': 'contain', margin: '0 auto' }"
          :src="data.image || fallbackImage"
          :alt="data.name"
          @error="onImageLoadFailure"
          @click="handleImageClick"
      />

      <div class="text-subtitle1 text-weight-bold q-px-sm">
        {{ name }}
      </div>
    </div>

    <div class="border-bottom q-pa-xs text-caption text-weight-bold">
      <div class="row q-pb-xs">
        <div class="q-mr-sm q-px-xs" style="min-width: 80px;">
          {{ $t('SKU') }}
        </div>

        <div>
          <q-badge
              color="info"
              :label="data?._embedded?.productOffer?.sku || data.sku ||''"
              class="q-py-xs"
          />
        </div>
      </div>

      <div class="row q-pb-xs">
        <div
            class="q-mr-sm q-px-xs"
            :class="barcodes.length > 1 ? 'bg-blue text-white' : ''"
            style="min-width: 80px;"
            @click="toggleBarcodes"
        >
          {{ $t('Barcode') }}
        </div>

        <div>
          <q-badge
              color="warning"
              :label="data?.sku || '--'"
              class="q-py-xs"
          />
        </div>
      </div>

      <q-slide-transition>
        <div v-if="isOpen">
          <q-badge
              v-for="barcode in barcodes"
              :key="barcode"
              color="warning"
              :label="barcode || '--'"
              class="q-py-xs q-mr-sm"
          />
        </div>
      </q-slide-transition>

      <div class="row">
        <div class="q-mr-sm q-px-xs" style="min-width: 80px;">
          {{ $t('Article') }}
        </div>

        <div>
          <q-badge
              color="positive"
              :label="data.article || '--'"
              class="q-py-xs"
          />
        </div>
      </div>
    </div>

    <q-dialog
        v-model="isOpenImage"
        persistent
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
        @click="handleImage"
    >
      <q-card
          class="row items-center justify-center"
          style="background: rgba(0, 0, 0, .7);"
      >
        <img
            style="height: 90vh; width: 90vw; object-fit: contain;"
            :src="image || fallbackImage"
            @error="onImageLoadFailure"
            @click="handleImage"
        >
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'ProductSection',
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      required: true,
      default () {
        return {
          image: {
            width: 54,
            height: 70
          }
        }
      }
    }
  },
  data () {
    return {
      isOpen: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpenImage: false,
      image: null
    }
  },
  computed: {
    name () {
      return this.data.name || `${this.$t('Id')}: ${this.data.id}`
    },
    barcodes () {
      if (!this.data.barcodes) {
        return []
      }

      if (typeof this.data.barcodes === 'string') {
        return []
      }

      return this.data.barcodes
    },
    getOptions () {
      return this.options
    }
  },
  methods: {
    toggleBarcodes () {
      if (this.barcodes.length <= 1) {
        return
      }

      this.isOpen = !this.isOpen
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    handleImageClick () {
      this.image = this.data.image || this.fallbackImage
      this.isOpenImage = true
    },
    handleImage () {
      this.isOpenImage = false
      this.image = null
    }
  }
}
</script>
